rodijco.controller 'ProductsGroupFormController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, location, httpService) ->

    scope.formData = {}
    scope.formData.product_fields = {
      0: {}
      1: {}
      2: {}
    }

    scope.typeOptions =
      [{
        id: '0',
        name: 'Text veld'},
      {
        id: '1',
        name: 'Text area'},
      {
        id: '2',
        name: 'Werklast'
      }]

    scope.edit = false
    scope.edit = true if routeParams.id != 'new'

    if scope.edit
      httpService.get "products/groups/#{routeParams.id}", (response) ->
        scope.formData = response.data

    scope.save = (formData, forceNew = false) ->
      formData._id = routeParams.id

      if forceNew
        formData._id = 'new'
        routeParams.id = 'new'

      httpService.post "products/groups/#{routeParams.id}", formData, (response) ->
        if response.success
          scope.formData = {}
          Materialize.toast 'Product groep opgeslagen', DIALOG_DURATION
          if routeParams.id == 'new'
            location.path('/products')
          else
            location.path('/products/' + routeParams.id)
        else
          scope.message = response.data?.message

    scope.addProductField = ->
      len = Object.keys(scope.formData.product_fields).length
      len = 0 if not len

      scope.formData.product_fields[len++] = {}

]
